import {
  ApolloClient,
  ApolloError,
  TypedDocumentNode,
  gql,
  NormalizedCacheObject,
  ApolloQueryResult,
} from '@apollo/client';
import {
  getSEOSegmentationBreadcrumbsVariables,
  getSEOSegmentationBreadcrumbs as getSEOSegmentationBreadcrumbsType,
} from '@/__generated__/getSEOSegmentationBreadcrumbs';
import { apolloClientQuery } from './utils';

export const GET_SEGMENTATION_BREADCRUMBS: TypedDocumentNode<
  getSEOSegmentationBreadcrumbsType,
  getSEOSegmentationBreadcrumbsVariables
> = gql`
  query getSEOSegmentationBreadcrumbs($seoSegmentId: ID!) {
    getSEOSegmentationBreadcrumbs(seoSegmentId: $seoSegmentId) {
      breadcrumbs {
        content
        url
      }
    }
  }
`;

type GetSegmentationBreadcrumbsQuery = (
  client: ApolloClient<NormalizedCacheObject>,
  variables: getSEOSegmentationBreadcrumbsVariables
) => Promise<
  | {
      queryResponse: ApolloQueryResult<getSEOSegmentationBreadcrumbsType>;
      error: null;
    }
  | {
      queryResponse: null;
      error: ApolloError;
    }
>;

export const getSegmentationBreadcrumbs: GetSegmentationBreadcrumbsQuery = async (
  client,
  variables
) => {
  return apolloClientQuery(client, GET_SEGMENTATION_BREADCRUMBS, variables);
};
